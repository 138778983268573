<script>
  /**
   * A checkbox with a label, which can optionally be hidden.
   */
  export default {
    model: {
      prop: 'checked',
      event: 'change',
    },
    props: {
      /** Whether the checkbox is checked. */
      checked: {
        type: Boolean,
        default: false,
      },
      /** Whether the checkbox is disabled. A disabled checkbox has an opaque border and cannot be focused or toggled. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** The size of the box. */
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['sm', 'md'].includes(value),
      },
      /** The vertical alignment of the box relative to the label. */
      verticalAlignment: {
        type: String,
        default: 'top',
        validator: (value) => ['top', 'center'].includes(value),
      },
      /** Whether the label is hidden, in which case only the box is shown. Even when hidden, the label remains accessible to screen readers. */
      labelHidden: {
        type: Boolean,
        default: false,
      },
      /** The color scheme of the checkbox */
      color: {
        type: String,
        default: 'primary',
        validator: (value) => ['dawn', 'primary'].includes(value),
      },
    },
    computed: {
      innerChecked: {
        get() {
          return this.checked
        },
        set(value) {
          /** Emitted when the checkbox is checked on or off. */
          this.$emit('change', value)
        },
      },
      verticalAlignmentClass() {
        switch (this.verticalAlignment) {
          case 'top':
            return 'items-start'
          case 'center':
            return 'items-center'
          default:
            return ''
        }
      },
      listeners() {
        const { change, ...listeners } = this.$listeners
        return listeners
      },
    },
  }
</script>

<template>
  <label
    :class="`flex ${verticalAlignmentClass} ${
      disabled ? '' : 'cursor-pointer'
    } font-normal leading-tight`"
  >
    <span class="flex items-center flex-shrink-0">
      <!-- Zero-width space character, used to align the checkbox properly -->
      &#8203;
      <span :class="`${size === 'sm' ? 'w-4 h-4' : 'w-6 h-6'}`">
        <input
          v-model="innerChecked"
          type="checkbox"
          :disabled="disabled"
          class="checkbox-input absolute left-0 top-0 opacity-0"
          v-on="listeners"
        />
        <!-- @slot Optional. The checkbox’s box, if you want to override it. -->
        <slot name="box">
          <span
            class="checkbox-box flex justify-center items-center w-full h-full rounded-sm border transform transition duration-200"
            :class="color === 'dawn' ? 'checkbox-box--dawn' : 'checkbox-box--primary'"
          >
            <BaseIcon :size="size === 'sm' ? 3 : 4" class="checkbox-box-checkmark opacity-0">
              <IconCheckmarkWide />
            </BaseIcon>
          </span>
        </slot>
      </span>
    </span>
    <span
      v-if="!labelHidden"
      :class="`checkbox-label ${size === 'sm' ? 'ml-2' : 'ml-3 mt-2px'} block`"
      data-cy="checkbox-label"
    >
      <span class="-m-1 block p-1 overflow-hidden ellipsis">
        <!-- @slot The checkbox’s label. Should contain text. -->
        <slot />
      </span>
    </span>
    <span v-else class="sr-only">
      <slot />
    </span>
  </label>
</template>

<style lang="postcss" scoped>
  .checkbox-box {
    @apply border-2 border-gray;

    .checkbox-input:not(:disabled):focus ~ & {
      @apply border-gray-active border-opacity-50 shadow-outline-black-50;
    }

    .checkbox-input:not(:disabled):hover ~ & {
      @apply bg-gray-hover;
    }

    .checkbox-input:not(:disabled):active ~ & {
      @apply border-gray-active scale-95 transition-none;
    }

    .checkbox-input:not(:disabled):checked ~ & {
      @apply border-transparent bg-primary;

      .checkbox-box-checkmark {
        @apply opacity-100 text-white;
      }
    }

    .checkbox-input:not(:disabled):checked:hover ~ & {
      @apply bg-primary-bright;
    }

    .checkbox-input:not(:disabled):checked:focus ~ & {
      @apply border-primary-dark border-opacity-50 shadow-outline-primary-50;
    }

    .checkbox-input:not(:disabled):checked:active ~ & {
      @apply border-primary-dark;
    }

    &--primary {
      .checkbox-input:disabled ~ & {
        @apply border-2 border-gray-opacity42;
      }

      .checkbox-input:disabled:checked ~ & {
        @apply border-transparent bg-gray-opacity42;

        .checkbox-box-checkmark {
          @apply opacity-100 text-white;
        }
      }
    }

    &--dawn {
      @apply border-dawn bg-primary rounded-none;

      .checkbox-input:not(:disabled):focus ~ & {
        @apply border-gray-active border-opacity-50 shadow-outline-gray-50;
      }

      .checkbox-input:not(:disabled):hover ~ & {
        @apply bg-dawn;

        .checkbox-box-checkmark {
          @apply opacity-100 text-primary;
        }
      }

      .checkbox-input:not(:disabled):active ~ & {
        @apply border-dawn scale-95 transition-none;
      }

      .checkbox-input:not(:disabled):checked ~ & {
        @apply bg-dawn;

        .checkbox-box-checkmark {
          @apply opacity-100 text-primary;
        }
      }

      .checkbox-input:not(:disabled):checked:hover ~ & {
        @apply bg-dawn-dk1;
      }

      .checkbox-input:not(:disabled):checked:focus ~ & {
        @apply border-dawn-dk1 shadow-outline-gray-50;
      }

      .checkbox-input:not(:disabled):checked:active ~ & {
        @apply border-dawn-dk1;
      }

      .checkbox-input:disabled ~ & {
        @apply border-2 border-dawn-opacity42;
      }

      .checkbox-input:disabled:checked ~ & {
        @apply border-transparent bg-dawn-opacity42;

        .checkbox-box-checkmark {
          @apply opacity-100 text-primary;
        }
      }
    }
  }
</style>
